/**
 * Sentry Setup
 */
import * as Sentry from '@sentry/react';
if (window.appSentryConfig)
  Sentry.init({
    dsn: window.appSentryConfig.dsn,
    integrations: [
      Sentry.browserTracingIntegration({
        traceFetch: true,
        traceXHR: true,
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: window.appSentryConfig.tracesSampleRate,
    tracePropagationTargets: window.appSentryConfig.tracePropagationTargets,
    // Session Replay
    replaysSessionSampleRate: window.appSentryConfig.replaysSessionSampleRate,
    replaysOnErrorSampleRate: window.appSentryConfig.replaysOnErrorSampleRate,
  });

import { toast } from 'sonner';
window.toast = toast;

setLocale({
  mixed: {
    required: 'Value is required',
  },
  number: {
    min: 'Value must at least ${min}',
    max: 'Value must be at most ${max}',
  },
});

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from 'axios';
import { setLocale } from 'yup';

window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

import Echo from 'laravel-echo';

import Pusher from 'pusher-js';

window.Pusher = Pusher;
window.Echo = new Echo(window.appBroadcastingConfig);
