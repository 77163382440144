import { createInertiaApp } from '@inertiajs/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { createRoot } from 'react-dom/client';
import { Toaster } from 'sonner';
import '../css/app.css';
import './bootstrap';

// noinspection JSIgnoredPromiseFromCall
createInertiaApp({
  title: (title) => `${title} - ${import.meta.env.VITE_APP_NAME}`,
  page: window.inertiaInitialPageData,
  progress: {
    color: '#4b5563',
  },
  resolve: (name) => resolvePageComponent(`./Pages/${name}.tsx`, import.meta.glob('./Pages/**/*.tsx')),
  setup: ({ el, App, props }) => {
    const queryClient = new QueryClient();

    createRoot(el).render(
      <QueryClientProvider client={queryClient}>
        <Toaster />
        <App {...props} />
      </QueryClientProvider>,
    );
  },
});
